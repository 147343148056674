<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'ThemeGroupbuyingList' }">主題團購管理</b-breadcrumb-item>
            <b-breadcrumb-item active>查看主題團購</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2 align-items-center">
        <div class="col-12 d-flex">
          <h4 class="font-weight-bold">查看主題團購</h4>
          <div class="ml-2">
            <b-button
              v-if="canEdit()"
              variant="inverse-warning"
              :to="{ name: 'ThemeGroupbuyingEdit', params: { themeGroupbuyingId: $route.params.themeGroupbuyingId } }"
            >
              編輯團購
            </b-button>
            <b-button
              class="ml-1"
              v-if="canDelete()"
              variant="inverse-danger"
              @click="showDeleteThemeWindow()"
            >
              刪除團購
            </b-button>
          </div>
        </div>
      </div>

      <theme-groupbuying-form
        v-if="isFetched"
        ref="form"
        :form="form"
        :start-at="startAt"
        :end-at="endAt"
       />

      <div class="d-flex justify-content-center">
        <b-button class="mr-3" variant="outline-danger" @click="$router.push({ name: 'ThemeGroupbuyingList' })">返回</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getHours, getMinutes, isBefore } from "date-fns";
import themeGroupbuyingApi from "@/apis/themeGroupbuying";
import ThemeGroupbuyingForm from "./ThemeGroupbuyingForm"
import checkPermission from "@/mixins/checkPermission";
import * as consts from "@/consts";

export default {
  mixins: [checkPermission],
  components: { ThemeGroupbuyingForm },
  data() {
    return {
      isFetched: false,
      startAt: {
        date: new Date(),
        time: {
          HH: "00",
          mm: "00",
        },
      },
      endAt: {
        date: new Date(),
        time: {
          HH: "23",
          mm: "59",
        },
      },
      form: {
        type:null,
        title: "",
        description: null,
        pic_url: "",
        tag_title: "",
        tag_color: null,
        tag_font_color: null,
        footer_contents: [
          {
            label: "",
            uri: "",
          },
        ],
        order: null,
        start_at: null,
        end_at: null,
      },
    }
  },

  async created() {
    await this.fetchThemeGroupbuying();
  },

  methods: {
    async fetchThemeGroupbuying() {
      const response = await themeGroupbuyingApi.getThemeGroupbuying(this.$route.params.themeGroupbuyingId)
      this.form = response.data.data;
      const { start_at, end_at } = response.data.data;
      this.startAt = {
        date: new Date(start_at),
        time: {
          HH: String(getHours(new Date(start_at))).padStart(2, "0"),
          mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
        },
      };
      this.endAt = {
        date: new Date(end_at),
        time: {
          HH: String(getHours(new Date(end_at))).padStart(2, "0"),
          mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
        },
      };
      this.isFetched = true;
    },
    canEdit() {
      const endDate = new Date(this.form.end_at);

      if (! this.checkPermission([consts.THEME_GROUPBUYING_MODIFY])
        || this.form.deleted_at
        || isBefore(endDate, new Date)
      ) {
        return false;
      }

      return true;
    },
    canDelete() {
      const startDate = new Date(this.form.start_at);
      const endDate = new Date(this.form.end_at);
      const now = new Date();

      if (! this.checkPermission([consts.THEME_GROUPBUYING_MODIFY])
        || this.form.deleted_at
        || (isBefore(startDate, now) && isBefore(now, endDate))
      ) {
        return false;
      }

      return true;
    },
    showDeleteThemeWindow() {
      this.$swal({
        title: "確定要刪除嗎？",
        type: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "確定刪除",
        cancelButtonText: "返回",
        confirmButtonClass: "btn btn-lg btn-primary m-1 h3",
        cancelButtonClass: "btn btn-lg btn-outline-primary m-1 h3",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.deleteThemeGroupbuying(this.form);
        }
      });
    },
    async deleteThemeGroupbuying(themeGroupbuying) {
      let response = await themeGroupbuyingApi.removeThemeGroupbuying(themeGroupbuying);
      if (response.status === 200) {
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "成功刪除",
        });
        this.$router.push({ name: 'ThemeGroupbuyingList' });
      } else {
        if (response.data.message) {
          this.$swal.fire({
            title: '錯誤',
            type: "error",
            text: response.data.message,
          });
        }
      }
    },
  },
};
</script>
